import Analitics from "@/shared/Analitics";
import BannerFlotante from "@/widgets/Banner/Templates/BannerFlotante";
import {
  BannerFlotante2,
  BannerFlotante4
} from "@/shared/ContentBanner";
import DfpHome from "@/shared/DFP/Home";
import Header from "@/shared/Header";
import Footer from "@/shared/Footer";
import { MainTitle } from "./styles";
import LdjsonOrg from "@/shared/Schemas/Organization";
import LdjsonItemList from "@/shared/Schemas/ItemList";
import LdjsonNewsMediaOrg from "@/shared/Schemas/NewsMediaOrganization/index.js";
import LdjsonWebsite from "@/shared/Schemas/Website/index.js";
import LdjsonBreadcrumbList from "@/shared/Schemas/BreadcrumbList/index.js";
import { MetaTagsSubhome } from "@/shared/MetaTags";
import Meter from "@/shared/Meter/index.jsx";
import Autorefresh from "@/shared/Autorefresh/index.jsx";
import TemplateProvider from "@/context/Template/TemplateProvider.jsx";
import { useHome } from "./hooks/useHome";
import { useEffect } from "react";
import { getCookie, setCookie } from "helpers";

const TemplateBuilder = (props) => {

  const { formatContent, ...rest } = useHome(props);

  useEffect(() => {
    if(window) {
      window.abTestUpdate = () => {
        const isRandomDynamicNews = getCookie("randomDynamicNews");
        const newValue = isRandomDynamicNews === "0" ? "1" : "0";
        setCookie("randomDynamicNews", newValue, 30);
        console.info("AB Test", newValue === "0" ? "Fijo" : "Dinámico"); 
      }
    }
    const isRandomDynamicNews = getCookie("randomDynamicNews");
    console.info("AB Test", isRandomDynamicNews === "0" ? "Fijo" : "Dinámico"); 
  }, [])
  

  return (
    <TemplateProvider data={{ ...rest, content: formatContent }} >
      <MetaTagsSubhome />
      {!rest?.preview &&
        <>
          <Analitics />
          <LdjsonOrg />
          <LdjsonItemList />
          <LdjsonNewsMediaOrg />
          <LdjsonWebsite />
          <LdjsonBreadcrumbList />
          <DfpHome />
          <Header />
        </>
      }
      <BannerFlotante isPreview={rest?.preview} slotId={"1"} />
      <BannerFlotante isPreview={rest?.preview} slotId={"6"} />
      <BannerFlotante4 id="b-main-top" className="b-hide">
        <BannerFlotante isPreview={rest?.preview} slotId={"4"} />
      </BannerFlotante4>
      <BannerFlotante2>
        <BannerFlotante isPreview={rest?.preview} slotId={"2"} />
      </BannerFlotante2>
      <BannerFlotante isPreview={rest?.preview} slotId={"5"} />
      <div>
        <MainTitle>Clarín</MainTitle>
        {rest?.Pagegrowler.generateDOM(props, rest?.preview)}
      </div>
      {!rest?.preview &&
        <>
          {formatContent?.metaRefresh?.status &&
            <Autorefresh timerRefresh={formatContent?.metaRefresh?.timer} />
          }
          <Meter />
          <Footer />
        </>
      }
    </TemplateProvider>
  );
}

export default TemplateBuilder;